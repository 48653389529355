import { GoogleLogin } from '@react-oauth/google';
import logo from './logo.svg';
import './App.css';
import { useState } from 'react';
import axios from 'axios';

function App() {

  const [token, setToken] = useState();
  const [response, setResponse] = useState();

  function getData(url, options = {}) {
    return axios(url, { 
      headers: {
          'Authorization': 'Bearer '+ token,
      }, ...options
    }).then(response => {
      console.debug(response);
      setResponse(JSON.stringify(response, null, 2));
      return response;
    }).catch(error => {
      console.debug(error);
      setResponse(JSON.stringify(error.message, null, 2));
    })
  }

  function getHelloWorld() {
    return getData('/api/v1/hello_world_authenticated');
  }

  function deviceConnect() {
    getData('/api/v1/device/authorize_test').then(response => {
      if (!response) return;
      try {
        let fitbitAuthUrl = new URL(response.data);
        window.location.assign(fitbitAuthUrl);
      } catch (e) {
        console.warn(e);
      }
    });
  }

  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <GoogleLogin
          onSuccess={credentialResponse => {
            console.debug(credentialResponse);
            setToken(credentialResponse.credential);
          }}

          theme="filled_black"
          // text="continue_with"
          shape="circle"
        
          onError={() => {
            console.log('Login Failed');
          }}
          useOneTap
        />
        { token && <span>Authenticated</span> }
        {/* <a
          className="App-link"
          href="/api"
          onClick={fetchApiData}
          target="_blank"
        >
          API
        </a> */}
      </header>
      <main className="App-main">
        <button onClick={getHelloWorld}>send api request</button>
        <button onClick={deviceConnect}>device connect</button>
        { response && (<pre><code>{ response }</code></pre>) }
      </main>
    </div>
  );
}

export default App;
